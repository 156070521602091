// import About from './components/About';
import Construction from './components/construction/Construction';
// import Contact from './components/Contact';
// import Experience from './components/Experience';
// import Home from './components/Home';
// import NavBar from './components/NavBar';
// import Portfolio from './components/Portfolio';

function App() {
  return (
    <Construction />
    // <div>
    //   <NavBar/>
    //   <Home/>
    //   <Portfolio/>
    //   <Experience/>
    //   <About/>
    //   <Contact/>

    // </div>
  );
}

export default App;
